<template>
  <v-app style="background-color: #efefef">
    <app-header></app-header>
    <app-view></app-view>
    <app-side-bar></app-side-bar>
  </v-app>
</template>

<script>
import View from "./components/layout/View";
import Header from "./components/layout/Header";
import Drawer from "./components/layout/Drawer";

export default {
  name: "App",
  components: {
    AppView: View,
    AppHeader: Header,
    AppSideBar: Drawer
  }
};
</script>
