import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const Home = () => import("@/views/Home");
const BusinessMarket = () => import("@/views/BusinessMarket");
const BusinessAbout = () => import("@/views/BusinessAbout");
const BusinessOrders = () => import("@/views/BusinessOrders");

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/store/:businessName",
    redirect: { name: "businessMarketHome" },
    name: "businessMarket",
    component: BusinessMarket,
    props: true
  },
  {
    path: "/store/:businessName/home",
    name: "businessMarketHome",
    component: BusinessMarket,
    props: true
  },
  {
    path: "/store/:businessName/orders",
    name: "businessOrders",
    component: BusinessOrders,
    props: true
  },
  {
    path: "/store/:businessName/help",
    name: "businessAbout",
    component: BusinessAbout,
    props: true
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
