<template>
  <v-navigation-drawer
    app
    v-model="openOrCloseDrawer"
    width="260"
    :height="$vuetify.breakpoint.height + 'px'"
    color="white"
    clipped
    v-if="!$vuetify.breakpoint.lgAndUp"
  >
    <template v-slot:prepend>
      <div class="py-2 text-center background">
        <v-img src="../../assets/ssencial-logo.svg" contain height="20"></v-img>
        <h4 class="mt-1 ma-0 text-h5">Marketplace</h4>
      </div>
    </template>
    <v-list nav dense v-if="hideMenu">
      <v-list-item-group v-model="selectedItem" color="primary">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          @click="hideMenu = false"
        >
          <v-list-item-icon class="mr-2 ml-2">
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-btn
      class="text-capitalize justify-start mt-1"
      text
      block
      v-if="!hideMenu"
      @click="hideMenu = true"
    >
      <v-icon size="16" class="mr-1">arrow_back</v-icon>Menu principal
    </v-btn>
    <v-container v-if="!hideMenu">
      <div v-if="businessData">
        <app-drawer-business></app-drawer-business>
      </div>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import EventBus from "@/config/bus";
import DrawerBusiness from "@/components/marketplace/DrawerBusiness";
import { mapState } from "vuex";

export default {
  data: () => ({
    // :: data
    openOrCloseDrawer: false,
    hideMenu: false,
    items: [
      { text: "Inicio", icon: "home" },
      { text: "Categorias", icon: "category" },
      { text: "Novedades", icon: "star_rate" },
      { text: "Tiendas", icon: "store" },
      { text: "Ayuda", icon: "help_center" },
      { text: "Descargar", icon: "file_download" }
    ]
  }),
  computed: {
    ...mapState("business", ["businessData"])
  },
  mounted() {
    EventBus.$on("TOGGLE_DRAWER", () => {
      this.openOrCloseDrawer = !this.openOrCloseDrawer;
    });
  },
  components: {
    AppDrawerBusiness: DrawerBusiness
  }
};
</script>

<style></style>
