export default () => {
  return {
    headerTitle: "",
    viewMode: "list",
    days: [
      { letter: "L", value: 0 },
      { letter: "M", value: 1 },
      { letter: "M", value: 2 },
      { letter: "J", value: 3 },
      { letter: "V", value: 4 },
      { letter: "S", value: 5 },
      { letter: "D", value: 6 }
    ],
    categories: [
      { categorie: "Belleza", value: "beauty" },
      { categorie: "Indumentaria", value: "clothing" },
      { categorie: "Electrónica", value: "electronics" },
      { categorie: "Muebles", value: "furniture" },
      { categorie: "Víveres", value: "groceries" },
      { categorie: "Artesanías", value: "handcrafts" },
      { categorie: "Joyería", value: "jewelry" },
      { categorie: "pinturas", value: "painting" },
      { categorie: "fotografía", value: "photography" },
      { categorie: "Restaurantes", value: "restaurants" },
      { categorie: "Otros alimentos y bebidas", value: "other_food_drink" },
      { categorie: "Servicios", value: "services" },
      { categorie: "Deportes", value: "sports" },
      { categorie: "Juguetes", value: "toys" },
      { categorie: "Servicios virtuales", value: "virtual_services" },
      { categorie: "Otro", value: "other" }
    ]
  };
};
