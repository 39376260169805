<template>
  <div>
    <v-scroll-x-transition>
      <v-container
        v-if="businessData"
        :class="{ 'pt-0': !$vuetify.breakpoint.lgAndUp }"
      >
        <div class="text-center mb-2" v-if="businessData.thumbnail">
          <v-avatar size="70">
            <img :src="businessData.thumbnail" alt="business profile picture" />
          </v-avatar>
        </div>
        <div class="text-h4 text-capitalize">{{ businessData.name }}</div>
        <div class="text-subtitle-1 font-weight-light text-capitalize">
          <v-icon size="20">category</v-icon>
          {{ getCategoryName(businessData.categorie) }}
        </div>
        <div class="text-subtitle-1 font-weight-light">
          <v-icon size="18">location_on</v-icon>
          {{ businessData.location | locationFormat }}
        </div>
        <div class="text-subtitle-1 font-weight-light text-center mt-2">
          <v-icon size="20" class="mr-1">date_range</v-icon
          ><span>Días laborables</span>
          <v-item-group>
            <v-item v-for="day in days" :key="day.value + '-day'">
              <v-chip
                :color="
                  businessData.business_hours_data.days.includes(day.value)
                    ? customerColor
                    : ''
                "
                x-small
                :text-color="
                  businessData.business_hours_data.days.includes(day.value)
                    ? customerTextColor
                    : 'black'
                "
                class="mr-1"
                style="padding: 12px 8px !important"
              >
                {{ day.letter }}
              </v-chip>
            </v-item>
          </v-item-group>
          <div class="mt-2">
            {{ businessData.business_hours_data | timeFormat }}
          </div>
        </div>
        <div class="my-3">
          <v-list nav dense class="pa-0">
            <v-list-item-group
              :color="
                getBusinessDesign ? getBusinessDesign.primary_color : 'primary'
              "
              :class="{ background: $vuetify.breakpoint.lgAndUp }"
            >
              <v-list-item
                :to="{ name: item.name, params: item.params }"
                :class="{
                  'white lighten-1': $vuetify.breakpoint.lgAndUp,
                  'grey lighten-4': !$vuetify.breakpoint.lgAndUp
                }"
                v-for="(item, i) in businessSections"
                :key="i"
                @click="hideMenu = false"
              >
                <v-list-item-icon class="mr-2 ml-0">
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
        <div id="catefories" class="mt-6">
          <p class="subheadline font-weight-medium mb-1">Categorías</p>
          <!-- <template v-for="n in 6"> -->
          <div class="text-body-2 my-1">
            <a href="#" class="ml-1 text-decoration-none text--secondary"
              >Alimentos y Bebidas</a
            >
          </div>
          <div class="text-body-2 my-1">
            <a href="#" class="ml-1 text-decoration-none text--secondary"
              >Belleza y cuidado Personal</a
            >
          </div>
          <div class="text-body-2 my-1">
            <a href="#" class="ml-1 text-decoration-none text--secondary"
              >Celulares y Telefonía</a
            >
          </div>
          <div class="text-body-2 my-1">
            <a href="#" class="ml-1 text-decoration-none text--secondary"
              >Deportes y Fitness</a
            >
          </div>
          <div class="text-body-2 my-1">
            <a href="#" class="ml-1 text-decoration-none text--secondary"
              >Carros</a
            >
          </div>
          <div class="text-body-2 my-1">
            <a href="#" class="ml-1 text-decoration-none"
              ><v-icon small>expand_more</v-icon> Mostrar mas</a
            >
          </div>
          <!-- </template> -->
        </div>
      </v-container>
    </v-scroll-x-transition>
    <v-scroll-x-transition>
      <v-container v-if="notFoundBuisness">
        <p class="mb-0 caption">
          La información del negocio no fue encontrada
        </p>
      </v-container>
    </v-scroll-x-transition>
    <v-scroll-x-transition>
      <v-container v-if="!businessData" class="text-center">
        <p class="mb-2 caption">
          Cargando información del negocio
        </p>
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-container>
    </v-scroll-x-transition>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      businessSections: [
        {
          text: "Productos",
          icon: "shopping_basket",
          params: { name: this.businessName },
          name: "businessMarketHome"
        },
        {
          text: "Ordenes",
          icon: "archive",
          name: "businessOrders",
          params: { name: this.businessName }
        },
        {
          text: "Mas información",
          icon: "store",
          name: "businessAbout",
          params: { name: this.businessName }
        }
      ]
    };
  },
  methods: {
    getCategoryName(categorie) {
      const cate = this.categories.find(x => x.value === categorie).categorie;
      if (cate) return cate;
      return "";
    }
  },
  computed: {
    ...mapState("business", ["businessData"]),
    ...mapState("app", ["days", "categories"]),
    ...mapGetters("business", ["getBusinessDesign"]),
    customerColor() {
      if (this.getBusinessDesign) return this.getBusinessDesign.primary_color;
      else return "primary";
    },
    customerTextColor() {
      if (this.getBusinessDesign)
        return this.getBusinessDesign.header_color_text;
      else return "white";
    }
  },
  filters: {
    locationFormat(location) {
      return `${location.street_name}, ${location.city_name}, ${location.state_name}, México, C.P. ${location.postal_code}`;
    },
    timeFormat(timeObject) {
      return `${moment(timeObject.open, ["HH"]).format(
        "hh A"
      )} - ${moment(timeObject.close, ["HH"]).format("hh A")}`;
    }
  },
  props: {
    notFoundBuisness: {
      type: Boolean,
      require: true
    },
    businessName: {
      type: String
    }
  }
};
</script>

<style></style>
