import { errorMessage } from "@/services/MessageService";
import { firestore } from "@/config/firebase";

export default {
  async getBusinessData({ commit, dispatch }, business_id) {
    try {
      const branchOffices = [];
      const businessData = await firestore("business")
        .doc(business_id)
        .get();
      const businessBranchsData = await firestore("business")
        .doc(business_id)
        .collection("branch_offices")
        .get();
      businessBranchsData.forEach(branchOffice => {
        branchOffices.push(branchOffice.data());
      });
      commit("setBusinessData", {
        ...businessData.data(),
        branchOffices: branchOffices
      });
      dispatch("app/changeHeaderTitle", businessData.data().name, {
        root: true
      });
    } catch (error) {
      errorMessage({
        message:
          "No fue posible obtener los datos del negocio, " + error.message
      });
    }
  }
};
