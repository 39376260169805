import defaultState from "./state";

export default {
  setBusinessData(state, businessData) {
    state.businessData = businessData;
  },
  resetState(state) {
    Object.assign(state, defaultState());
  }
};
