import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

// :: MODULES
import business from "./business";
import app from "./app";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  supportCircular: true,
  storage: window.localStorage,
  // :: Modules that need persistence
  modules: ["app"]
});

const store = new Vuex.Store({
  modules: {
    business,
    app
  },
  plugins: [vuexLocal.plugin]
});

export default store;
