import { databaseDefault, businessDatabaseInstance } from "@/config/firebase";
import { errorMessage } from "@/services/MessageService";

export default {
  changeHeaderTitle: ({ commit }, title) => {
    commit("setHeaderTitle", title);
  },
  async checkCurrentBusiness({ rootState }, businessName) {
    try {
      const businessData = await databaseDefault(
        `business_marketplace/${businessName}`
      ).once("value");
      if (businessData.exists()) {
        if (rootState.business.businessData) {
          const businessID = rootState.business.businessData.id;
          if (businessID === businessData.val().id) return "OK";
          else return "NOT_SAME";
        } else return "NOT_SAME";
      } else {
        return "NOT_FOUND";
      }
    } catch {
      errorMessage({
        message: "No fue posible obtener la información del negocio"
      });
      return "ERROR";
    }
  },
  async initBuinessDatabase({ dispatch }, businessName) {
    try {
      const businessData = await databaseDefault(
        `business_marketplace/${businessName}`
      ).once("value");
      if (businessData.exists()) {
        await businessDatabaseInstance(businessData.val().url);
        await dispatch("business/getBusinessData", businessData.val().id, {
          root: true
        });
        return "OK";
      } else {
        return "NOT_FOUND";
      }
    } catch {
      errorMessage({
        message: "No fue posible obtener la información del negocio"
      });
      return "ERROR";
    }
  },
  changeViewModeList({ commit, state }, mode) {
    if (mode) {
      commit("setViewModeList", mode);
    } else {
      if (state.viewMode === "grid") commit("setViewModeList", "list");
      else commit("setViewModeList", "grid");
    }
  }
};
