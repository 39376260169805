// :: MessageService
// :: Emits the `SNACKBAR_SHOW` event through a global bus
import EventBus from "@/config/bus";

/**
 * Receives an object containing a message and a color for the message and emits the `SNACKBAR_SHOW` message
 * @param {*} messageObject  must contain message and color, color defaults to success
 */
export const showMessage = ({ message, color = "success" }) => {
  EventBus.$emit("SNACKBAR_SHOW", { message, color });
};

// :: errorMessage
// :: Receives an error
export const errorMessage = message => {
  return showMessage({ message, color: "error" });
};
