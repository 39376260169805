<template>
  <v-app-bar
    app
    :height="$vuetify.breakpoint.lgAndUp ? '70' : '50'"
    elevate-on-scroll
    :dark="customerTextColor"
    :color="getBusinessDesign ? getBusinessDesign.primary_color : 'black'"
  >
    <v-app-bar-nav-icon
      @click.stop="toogleDrawer"
      v-if="!$vuetify.breakpoint.lgAndUp"
    ></v-app-bar-nav-icon>

    <div class="mr-4" v-if="$vuetify.breakpoint.lgAndUp">
      <v-img
        src="../../assets/ssencial-logo-white.svg"
        contain
        width="30"
        height="30"
      ></v-img>
    </div>
    <v-spacer></v-spacer>
    <v-text-field
      v-if="$vuetify.breakpoint.lgAndUp"
      dense
      light
      hide-details
      class="white"
      placeholder="Buscar"
      outlined
    ></v-text-field>
    <v-spacer></v-spacer>
    <v-btn icon>
      <v-icon>shopping_cart</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { toggleNavegationDrawer } from "@/services/DrawerBus";
import { mapGetters } from "vuex";

export default {
  methods: {
    toogleDrawer() {
      toggleNavegationDrawer();
    }
  },
  computed: {
    ...mapGetters("business", ["getBusinessDesign"]),
    customerTextColor() {
      if (this.getBusinessDesign)
        if (this.getBusinessDesign.header_color_text === "white") return true;
        else return false;
      else return true;
    }
  }
};
</script>
