import defaultState from "./state";

export default {
  setHeaderTitle(state, title) {
    state.headerTitle = title;
  },
  resetState(state) {
    Object.assign(state, defaultState());
  },
  setViewModeList(state, mode) {
    state.viewMode = mode;
  }
};
